import React from 'react';

function ComingSoon() {
  return (
    <div className="py-20 bg-gray-50 text-center">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-4 text-light-blue">Account Access Coming Soon!</h1>
        <p className="text-xl text-gray-600">We're working hard to bring you a seamless account experience. Stay tuned!</p>
        <p className="text-xl text-gray-600">In the meantime, please download our app to register an account and try out Treasure Tracker today!</p>
      </div>
      <div className="flex flex-row justify-center sm:justify-center space-x-4">
              <a href="https://apps.apple.com/app/treasure-tracker" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-105">
                <img src="/images/app-store-badge.svg" alt="Download on App Store" className="h-10 sm:h-12 w-auto" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.treasuretracker" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-105">
                <img src="/images/google-play-badge.png" alt="Get it on Google Play" className="h-10 sm:h-12 w-auto" />
            </a>
        </div>
    </div>
  );
}

export default ComingSoon;