import React from 'react';

function DataDeletion() {
  return (
    <div className="data-deletion py-12 sm:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-5xl font-bold mb-6 sm:mb-8 text-center text-light-blue">Data Deletion Instructions</h1>
        <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md">
          <p className="mb-6 text-base sm:text-lg">
            At Treasure Tracker LLC, we value your privacy and are committed to protecting your personal information. While we do not store your personal data directly, we use certain information for login purposes and to analyze advertising metrics. In compliance with Facebook Platform rules and data protection regulations, we provide the following instructions for data deletion:
          </p>
          
          <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-light-blue">Facebook-Related Data Deletion</h2>
          <p className="mb-4 text-base sm:text-lg">
            To delete your activities associated with Treasure Tracker LLC on Facebook, please follow these steps:
          </p>
          <ol className="list-decimal list-inside mb-6 pl-4 text-base sm:text-lg">
            <li className="mb-2">Go to your Facebook Account's Settings & Privacy</li>
            <li className="mb-2">Click on "Settings"</li>
            <li className="mb-2">Navigate to "Apps and Websites"</li>
            <li className="mb-2">Locate and select the checkbox next to Treasure Tracker</li>
            <li className="mb-2">Click the "Remove" button</li>
          </ol>
          
          <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-light-blue">Additional Data Removal</h2>
          <p className="mb-4 text-base sm:text-lg">
            For any data that may be stored within the Treasure Tracker app:
          </p>
          <ol className="list-decimal list-inside mb-6 pl-4 text-base sm:text-lg">
            <li className="mb-2">Log in to your Treasure Tracker account</li>
            <li className="mb-2">Navigate to Settings</li>
            <li className="mb-2">Select "Delete Account"</li>
            <li className="mb-2">Confirm your decision to delete your account and associated data</li>
          </ol>
          
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <p className="font-semibold text-base sm:text-lg">Please note:</p>
            <p className="text-base sm:text-lg">Deleting your account will permanently remove all your data from our systems. This action cannot be undone.</p>
          </div>
          
          <p className="mb-4 text-base sm:text-lg">
            If you encounter any issues during the deletion process or need further assistance, please don't hesitate to contact our support team at{' '}
            <a href="mailto:jackson.white@mytreasuretracker.com" className="text-blue-600 hover:underline">
              jackson.white@mytreasuretracker.com
            </a>.
          </p>
          
          <p className="text-sm text-gray-600 mt-8">
            Last updated: {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DataDeletion;