import React, { useState, useEffect } from 'react';
import './HandwritingEffect.css';

const HandwritingEffect = ({ text, speed = 30 }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText((prev) => prev + text[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return (
    <h1 className="handwriting-text text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold mb-6 leading-tight text-light-blue">
      {text.split('').map((char, index) => (
        <span
          key={index}
          className={`writing-letter ${index < displayedText.length ? 'visible' : ''}`}
        >
          {char}
        </span>
      ))}
    </h1>
  );
};

export default HandwritingEffect;