import React, { useState, useEffect, useRef } from 'react';
import { FaPlay, FaTimes, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';

const UserStories = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const scrollRef = useRef(null);

  const videos = [
    { id: 1, title: "Mary's Story", thumbnail: "/images/user-story-4.png", youtubeId: "PG9eMwfl_Ho" },
    { id: 2, title: "Sue's Story", thumbnail: "/images/user-story-1.png", youtubeId: "HRxCANJ9ILk" },
    { id: 3, title: "Sandi's Story", thumbnail: "/images/user-story-2.png", youtubeId: "2237YR6WwFs" },
    { id: 4, title: "Fran's Story", thumbnail: "/images/user-story-3.png", youtubeId: "kGCDH4NGPfI" },
  ];

  const openVideo = (videoId) => {
    setActiveVideo(videoId);
    setIsMuted(true);
  };

  const closeVideo = () => {
    setActiveVideo(null);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  const handleMouseEnter = () => {
    if (scrollRef.current) {
      scrollRef.current.style.animationPlayState = 'paused';
    }
  };

  const handleMouseLeave = () => {
    if (scrollRef.current) {
      scrollRef.current.style.animationPlayState = 'running';
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      const scrollWidth = scrollContainer.scrollWidth;
      const animationDuration = 20; // in seconds, adjust for speed

      const keyframes = `
        @keyframes scroll {
          0% { transform: translateX(0); }
          100% { transform: translateX(${-scrollWidth / 2}px); }
        }
      `;

      const styleSheet = document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = keyframes;
      document.head.appendChild(styleSheet);

      scrollContainer.style.animation = `scroll ${animationDuration}s linear infinite`;

      return () => {
        document.head.removeChild(styleSheet);
      };
    }
  }, []);

  return (
    <section className="bg-white text-light-blue py-20">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center">User Stories</h2>
        <div className="relative overflow-hidden">
          <div
            ref={scrollRef}
            className="flex whitespace-nowrap"
            style={{ width: 'fit-content' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {[...videos, ...videos].map((video, index) => (
              <div
                key={`${video.id}-${index}`}
                className="inline-block w-80 mx-4"
                onClick={() => openVideo(video.id)}
              >
                <div className="relative cursor-pointer group bg-gray-200 rounded-lg shadow-lg overflow-hidden">
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 text-white transition-opacity duration-300 opacity-100 group-hover:opacity-0">
                    <h3 className="text-lg font-semibold mb-2">{video.title}</h3>
                    <p className="text-sm">We asked {video.title.split("'")[0]} what the stories were behind all their treasures in the house. Here are the answers:</p>
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-light-blue bg-opacity-60">
                    <FaPlay className="text-white text-4xl" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {activeVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-full max-w-4xl">
            <button
              onClick={closeVideo}
              className="absolute -top-10 right-0 text-white text-2xl hover:text-light-blue transition-colors duration-300"
            >
              <FaTimes />
            </button>
            <div className="bg-gray-200 rounded-lg shadow-lg aspect-video flex items-center justify-center">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videos.find(v => v.id === activeVideo).youtubeId}?autoplay=1&mute=${isMuted ? 1 : 0}`}
                title={videos.find(v => v.id === activeVideo).title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <button
                onClick={toggleMute}
                className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-colors duration-300"
              >
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UserStories;