import React from 'react';
import { Link } from 'react-router-dom';
import { FaXTwitter, FaInstagram, FaFacebookF, FaTiktok, FaLinkedinIn } from 'react-icons/fa6';

function Footer() {
  return (
    <footer className="bg-light-blue text-white py-6 px-4 sm:p-8">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <div className="text-center md:text-left">
          <p className="text-sm sm:text-base">&copy; 2024 Treasure Tracker. All rights reserved.</p>
        </div>
        <div className="social-media flex space-x-4">
          <a href="https://x.com/TreasureTrakLLC" target="_blank" rel="noopener noreferrer" className="hover:text-gold transition-colors transform hover:scale-110 duration-300">
            <FaXTwitter size={20} />
          </a>
          <a href="https://www.instagram.com/the_treasure_tracker/" target="_blank" rel="noopener noreferrer" className="hover:text-gold transition-colors transform hover:scale-110 duration-300">
            <FaInstagram size={20} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61554894507503r" target="_blank" rel="noopener noreferrer" className="hover:text-gold transition-colors transform hover:scale-110 duration-300">
            <FaFacebookF size={20} />
          </a>
          <a href="https://www.tiktok.com/@treasuretrackerllc?lang=en" target="_blank" rel="noopener noreferrer" className="hover:text-gold transition-colors transform hover:scale-110 duration-300">
            <FaTiktok size={20} />
          </a>
          <a href="https://www.linkedin.com/company/treasure-tracker/" target="_blank" rel="noopener noreferrer" className="hover:text-gold transition-colors transform hover:scale-110 duration-300">
            <FaLinkedinIn size={20} />
          </a>
        </div>
        <div className="legal-links text-xs sm:text-sm text-center md:text-right space-y-2 md:space-y-0">
          <Link to="/privacy-policy" className="block md:inline-block md:mr-4 hover:text-light-purple transition-colors">Privacy Policy</Link>
          <Link to="/terms-conditions" className="block md:inline-block md:mr-4 hover:text-light-purple transition-colors">Terms & Conditions</Link>
          <Link to="/data-deletion" className="block md:inline-block hover:text-light-purple transition-colors">Data Deletion</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;