import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://localhost:3001'
});

// For development only: ignore SSL certificate errors
process.env.NODE_ENV === 'development' && (instance.defaults.httpsAgent = new axios.create({
  httpsAgent: { rejectUnauthorized: false }
}).defaults.httpsAgent);

export default instance;