import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaDownload, FaPlay, FaCalendarAlt, FaUsers, FaClock, FaStar, FaChartLine } from 'react-icons/fa';

function CorporatePartners() {
  const [activeTab, setActiveTab] = useState('insurance');
  const [hoveredFaq, setHoveredFaq] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [clientCount, setClientCount] = useState(100);
  const [activeVideo, setActiveVideo] = useState(null);
  const howItWorksRefs = useRef([]);

  const calculateImpact = useCallback((count) => {
    if (count === 0) return { satisfactionIncrease: 0, timeSaved: 0 };
    const satisfactionIncrease = Math.min(count * 0.2, 95);
    const timeSaved = count * 2;
    return { satisfactionIncrease, timeSaved };
  }, []);

  const ImpactCard = React.memo(({ title, description, icon: Icon }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="bg-gray-100 p-6 rounded-lg shadow-md transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h3 className={`text-xl font-bold mb-4 text-light-blue flex items-center transition-all duration-300 ${isHovered ? 'transform translate-x-2' : ''}`}>
          <Icon className={`mr-2 transition-all duration-300 ${isHovered ? 'transform rotate-12' : ''}`} />
          {title}
        </h3>
        <p className={`transition-all duration-300 ${isHovered ? 'text-blue-600' : ''}`}>{description}</p>
      </div>
    );
  });

  function FAQ({ question, answer }) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div 
        className="border-b border-gray-200 py-6 group"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <h4 className="text-xl font-semibold text-light-blue transition-colors duration-300 group-hover:text-blue-600">
          {question}
        </h4>
        <div 
          className={`mt-4 text-gray-600 overflow-hidden transition-all duration-700 ease-in-out 
                      ${isHovered ? 'max-height-500 opacity-100' : 'max-height-0 opacity-0'}`}
        >
          <p className="transform transition-transform duration-700 ease-in-out origin-top
                        group-hover:translate-y-0 translate-y-4">
            {answer.split(' ').map((word, index) => (
              <React.Fragment key={index}>
                <span
                  className="inline-block transition-opacity duration-700 ease-in-out"
                  style={{
                    opacity: isHovered ? 1 : 0,
                    transitionDelay: `${index * 20}ms`,
                  }}
                >
                  {word}
                </span>
                {' '}
              </React.Fragment>
            ))}
          </p>
        </div>
      </div>
    );
  }

  const { satisfactionIncrease, timeSaved } = calculateImpact(clientCount);

  const partnerTypes = {
    insurance: {
      title: "Insurance Agents",
      benefits: [
        "Simplify client home inventories",
        "Expedite claims processing",
        "Ensure accurate coverage",
        "Strengthen client relationships"
      ],
      videoUrl: "/videos/insurance-partner-video.mp4",
      thumbnailUrl: "/images/insurance-thumbnail.png",
      pamphletUrl: "/pamphlets/insurance-partner-pamphlet.pdf"
    },
    estate: {
      title: "Estate Planners",
      benefits: [
        "Detailed asset documentation",
        "Sentimental value preservation",
        "Simplified inheritance planning",
        "Enhanced client services"
      ],
      videoUrl: "/videos/estate-partner-video.mp4",
      thumbnailUrl: "/images/estate-thumbnail.png",
      pamphletUrl: "/pamphlets/estate-partner-pamphlet.pdf"
    },
    realestate: {
      title: "Real Estate Agents",
      benefits: [
        "Pre-move inventory creation",
        "Moving process organization",
        "New home setup assistance",
        "Value-added service for clients"
      ],
      videoUrl: "/videos/realestate-partner-video.mp4",
      thumbnailUrl: "/images/realestate-thumbnail.png",
      pamphletUrl: "/pamphlets/realestate-partner-pamphlet.pdf"
    }
  };

  const faqs = [
    {
      question: "How does the partner program work?",
      answer: "Our partner program allows you to offer your clients a 3-month free subscription to Treasure Tracker. You'll receive brand placement within the app, and we aim to convert 5-10% of referred clients into paying users."
    },
    {
      question: "What's in it for me as a partner?",
      answer: "As a partner, you'll provide added value to your clients, potentially increase client retention, and earn commissions on converted users. Plus, you'll be offering a unique service that sets you apart from competitors."
    },
    {
      question: "How do I get started?",
      answer: "Simply schedule a call with us using the button above. We'll discuss your specific needs and set you up with our easy-to-use partner portal."
    }
  ];

  const steps = [
    { title: "1. Join Our Network", description: "Schedule a call and become a Treasure Tracker partner." },
    { title: "2. Refer Your Clients", description: "Offer your clients a 3-month free subscription to Treasure Tracker." },
    { title: "3. Grow Together", description: "Benefit from improved client relationships and potential commissions." }
  ];

  useEffect(() => {
    setFadeIn(true);
    const timer = setTimeout(() => setFadeIn(false), 300);
    return () => clearTimeout(timer);
  }, [activeTab]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-left');
          }
        });
      },
      { threshold: 0.1 }
    );

    howItWorksRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setActiveVideo(null);
  }, [activeTab]);

  return (
    <div className="corporate-partners bg-gray-100">
      <section className="bg-light-blue text-white py-12 sm:py-20">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-3xl sm:text-4xl font-bold mb-4">Elevate Your Client Services with Treasure Tracker</h1>
          <p className="text-lg sm:text-xl mb-8">Join our partner network and offer your clients a powerful tool for preserving their legacy</p>
          <a href="https://calendly.com/jackson-white-mytreasuretracker/30-minute-call" 
             target="_blank"
             rel="noopener noreferrer"
             className="bg-white text-light-blue px-6 py-3 rounded-full font-bold text-base sm:text-lg hover:bg-gray-200 transition duration-300">
            Schedule a Call
          </a>
        </div>
      </section>

      <section className="py-12 sm:py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold mb-8 sm:mb-12 text-center text-light-blue">Choose Your Partnership Track</h2>
          <div className="mb-8">
            <div className="flex flex-wrap justify-center mb-8 gap-2">
              {Object.keys(partnerTypes).map((type) => (
                <button
                  key={type}
                  className={`px-4 sm:px-8 py-2 sm:py-3 text-base sm:text-lg font-semibold transition-all duration-300 ${
                    activeTab === type 
                      ? 'bg-light-blue text-white shadow-lg transform scale-105' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => setActiveTab(type)}
                >
                  {partnerTypes[type].title}
                </button>
              ))}
            </div>
            <div className="bg-gray-100 p-4 sm:p-8 rounded-lg shadow-lg">
              <div className={`flex flex-col lg:flex-row gap-8 transition-opacity duration-300 ${fadeIn ? 'opacity-0' : 'opacity-100'}`}>
                <div className="lg:w-1/2 space-y-6">
                  <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-light-blue">{partnerTypes[activeTab].title} Benefits</h3>
                  <ul className="space-y-4 text-base sm:text-xl">
                    {partnerTypes[activeTab].benefits.map((benefit, index) => (
                      <li key={index} className="flex items-start">
                        <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="pt-4">
                    <a 
                      href={partnerTypes[activeTab].pamphletUrl}
                      download
                      className="bg-light-blue text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-bold text-base sm:text-lg hover:bg-blue-600 transition duration-300 inline-flex items-center"
                    >
                      <FaDownload className="mr-2" />
                      Download One-Pager
                    </a>
                  </div>
                </div>
                <div className="lg:w-1/2 mt-8 lg:mt-0">
                  <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-light-blue">Watch How It Works</h3>
                  <div className="relative w-full h-0 pb-[56.25%]">
              {activeVideo === activeTab ? (
                <video 
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                  autoPlay 
                  controls
                >
                  <source src={partnerTypes[activeTab].videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div className="absolute inset-0 bg-gray-300 rounded-lg overflow-hidden shadow-lg">
                  <img 
                    src={partnerTypes[activeTab].thumbnailUrl} 
                    alt={`${partnerTypes[activeTab].title} video thumbnail`}
                    className="w-full h-full object-cover"
                  />
                  <div 
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 group cursor-pointer transition-all duration-300 hover:bg-opacity-50"
                    onClick={() => setActiveVideo(activeTab)}
                  >
                    <FaPlay className="text-5xl text-white opacity-70 group-hover:opacity-100 group-hover:scale-110 transition-all duration-300" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="py-12 sm:py-20 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-8 sm:mb-12 text-center text-light-blue">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <div 
                key={index} 
                className="flex flex-col items-center transform translate-x-[-100%] opacity-0 transition-all duration-1000 ease-out"
                ref={el => howItWorksRefs.current[index] = el}
              >
                <h3 className="text-lg sm:text-xl font-bold mb-4 text-dark">{step.title}</h3>
                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md w-full">
                  <p className="text-sm sm:text-base">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>


      <section className="py-12 sm:py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-center text-light-blue">Partnership Impact</h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-12">
            <ImpactCard
              title="Enhanced Client Relationships"
              description="Offer a unique service that sets you apart and strengthens client trust."
              icon={FaUsers}
            />
            <ImpactCard
              title="Streamlined Processes"
              description="Save time on inventory management and claims processing for your clients."
              icon={FaClock}
            />
            <ImpactCard
              title="Increased Client Satisfaction"
              description="Provide added value that leads to higher client retention and referrals."
              icon={FaStar}
            />
            <ImpactCard
              title="Business Growth"
              description="Expand your service offerings and attract new clients with innovative solutions."
              icon={FaChartLine}
            />
          </div>

          <div className="max-w-md mx-auto bg-gray-100 p-6 sm:p-8 rounded-lg shadow-lg">
            <h3 className="text-xl sm:text-2xl font-bold mb-4 text-center text-light-blue">Estimate Your Impact</h3>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="clientCount">
                Number of clients you plan to refer annually:
              </label>
              <input
                type="number"
                id="clientCount"
                value={clientCount === 0 ? '' : clientCount}
                onChange={(e) => {
                  const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
                  setClientCount(isNaN(value) ? 0 : value);
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                min="0"
              />
            </div>
            <div className="text-center">
              <p className="text-base sm:text-lg font-bold mb-2">Potential Impact:</p>
              <p className="text-lg sm:text-xl mb-2">Up to <span className="text-light-blue">{satisfactionIncrease.toFixed(0)}%</span> increase in client satisfaction</p>
              <p className="text-lg sm:text-xl">Approximately <span className="text-light-blue">{timeSaved}</span> hours saved in client servicing</p>
            </div>
          </div>
        </div>
      </section>


      <section className="py-12 sm:py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-8 text-center text-light-blue">Frequently Asked Questions</h2>
          <div className="space-y-4 mx-auto" style={{ maxWidth: '1200px' }}>
            {faqs.map((faq, index) => (
              <FAQ 
                key={index}
                question={faq.question}
                answer={faq.answer}
                isHovered={hoveredFaq === index}
                onHover={(isHovered) => setHoveredFaq(isHovered ? index : null)}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="bg-light-blue text-white py-12 sm:py-16">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4">Ready to Elevate Your Client Services?</h2>
          <p className="text-base sm:text-xl mb-8">Join the Treasure Tracker partner network today and start offering your clients a unique and valuable service.</p>
          <a href="https://calendly.com/jackson-white-mytreasuretracker/30-minute-call" 
             target="_blank"
             rel="noopener noreferrer"
             className="bg-white text-light-blue px-6 py-3 rounded-full font-bold text-base sm:text-lg hover:bg-gray-200 transition duration-300 inline-flex items-center">
            <FaCalendarAlt className="mr-2" />
            Schedule Your Call Now
          </a>
        </div>
      </section>
    </div>
  );
}

export default CorporatePartners;