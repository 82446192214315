import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import MediaCarousel from '../components/MediaCarousel';
import UserStories from '../components/UserStories';
import HandwritingEffect from '../components/HandwritingEffect';

function Home() {
  const fadeRefs = useRef([]);
  const videoRef = useRef(null);
  const stepsRef = useRef([]);

  const steps = [
    { title: "Step 1", description: "Record your treasures", image: "/placeholder-image-1.jpg" },
    { title: "Step 2", description: "Share their stories", image: "/placeholder-image-2.jpg" },
    { title: "Step 3", description: "Connect with family", image: "/placeholder-image-3.jpg" },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    fadeRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    const stepsObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-left');
          }
        });
      },
      { threshold: 0.1 }
    );

    stepsRef.current.forEach((step) => {
      if (step) stepsObserver.observe(step);
    });

    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error("Autoplay was prevented:", error);
      });
    }

    return () => {
      observer.disconnect();
      stepsObserver.disconnect();
    };
  }, []);

  return (
    <div className="home text-dark">
      <div className="container mx-auto px-4 py-8 md:py-24">
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            {/* Mobile version visible on small screens */}
            <div className="md:hidden">
              <h1 className="text-2xl font-bold mb-2 text-light-blue">
                Your Treasures Tell Your Story.
              </h1>
              <h1 className="text-2xl font-bold mb-4 text-light-blue">
                Record It Today.
              </h1>
            </div>
            
            {/* Desktop version (visible on medium screens and above) */}
            <div className="hidden md:block">
              <HandwritingEffect 
                text="Your Treasures Tell Your Story."
                speed={40}
                className="text-3xl lg:text-4xl xl:text-5xl"
              />
              <HandwritingEffect 
                text="Record It Today."
                speed={100}
                className="text-3xl lg:text-4xl xl:text-5xl"
              />
            </div>
            
            <p className="text-base sm:text-lg md:w-3/4 mb-6 sm:mb-8 text-gray-700">
              Our treasures tell the story of our lives. But sometimes these stories
              are lost over time. Treasure Tracker aims to ensure our stories are
              never forgotten through our inventory app.
            </p>
            <div className="flex flex-row justify-center sm:justify-start space-x-4">
              <a href="https://apps.apple.com/app/treasure-tracker" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-105">
                <img src="/images/app-store-badge.svg" alt="Download on App Store" className="h-10 sm:h-12 w-auto" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.treasuretracker" target="_blank" rel="noopener noreferrer" className="transition-transform duration-300 hover:scale-105">
                <img src="/images/google-play-badge.png" alt="Get it on Google Play" className="h-10 sm:h-12 w-auto" />
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <div className="aspect-w-4 aspect-h-3 bg-gray-200 rounded-lg shadow-lg overflow-hidden">
              <video 
                ref={videoRef}
                className="w-full h-full object-cover"
                loop
                muted
                playsInline
              >
                <source src="/videos/app-preview.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-white py-10">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4 text-center text-light-blue">As Featured In</h2>
          <MediaCarousel />
        </div>
      </section>

      <section className="py-12 md:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-8 text-light-blue text-center">Welcome to Treasure Tracker</h2>
          <div className="space-y-8">
            <div ref={el => fadeRefs.current[0] = el} className="fade-in-section">
              <p className="text-lg md:text-xl text-gray-700 text-center">
                At Treasure Tracker, we believe that every cherished possession tells a story worth preserving. Our mission is to connect generations by transforming how you interact with your most treasured items.
              </p>
            </div>
            <div ref={el => fadeRefs.current[1] = el} className="fade-in-section">
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-light-blue text-center">Our Mission</h3>
              <p className="text-lg md:text-xl text-gray-700 text-center">
                We understand that your belongings are more than just objects—they are memories, stories, and legacies. Treasure Tracker is designed to preserve and share these precious moments.
              </p>
            </div>
            <div ref={el => fadeRefs.current[2] = el} className="fade-in-section">
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-light-blue text-center">Our Commitment</h3>
              <p className="text-lg md:text-xl text-gray-700 text-center">
                Security and simplicity are at the core of our service. We prioritize the protection of your data with robust security measures, ensuring your memories are safe and easily accessible.
              </p>
            </div>
            <div ref={el => fadeRefs.current[3] = el} className="fade-in-section">
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-light-blue text-center">Features and Benefits</h3>
              <ul className="text-lg md:text-xl text-gray-700 list-none pl-0 space-y-4">
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                  <span><strong>Storytelling and Legacy Building:</strong> Attach personal stories and historical context to each item, creating a rich narrative that connects generations. Share these stories with family and friends, preserving your legacy for years to come.</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                  <span><strong>Comprehensive Inventory Management:</strong> Easily organize your items by properties, rooms, and categories. Capture every detail with photos, descriptions, and documentation, ensuring a complete record of your treasures.</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                  <span><strong>Insurance and Estate Planning:</strong> Generate quick reports for insurance claims or estate planning. Our app simplifies the process, providing peace of mind and ensuring your valuables are accurately documented.</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                  <span><strong>Secure and User-Friendly Interface:</strong> Enjoy a seamless experience with our intuitive design. Your data is protected with top-tier security measures, including end-to-end encryption, so you can focus on what matters most—your memories.</span>
                </li>
                <li className="flex items-start">
                  <span className="inline-block w-4 h-4 bg-light-blue mr-3 mt-1.5 flex-shrink-0"></span>
                  <span><strong>Future-Ready Enhancements:</strong> Look forward to features like social media sharing, collaborative storytelling, and AI-assisted item entry. Our commitment to innovation ensures Treasure Tracker evolves with your needs.</span>
                </li>
              </ul>
              </div>
            <div ref={el => fadeRefs.current[4] = el} className="fade-in-section">
              <h3 className="text-xl md:text-2xl font-bold mb-4 text-light-blue text-center">Join Our Community</h3>
              <p className="text-lg md:text-xl text-gray-700 text-center mb-8">
                Treasure Tracker is more than an app—it's a bridge to your past and a gift to future generations. Join us in preserving the stories that make your family unique.
              </p>
              <div className="text-center">
                <Link to="/signup" className="bg-light-blue text-white px-6 py-3 rounded inline-block hover:bg-blue-600 transition duration-300">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UserStories />

      <section className="py-12 md:py-20 bg-gray-50 overflow-hidden">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-8 md:mb-12 text-center text-light-blue">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.map((step, index) => (
              <div 
                key={index} 
                className="flex flex-col items-center transform translate-x-[-100%] opacity-0 transition-all duration-1000 ease-out"
                ref={el => stepsRef.current[index] = el}
              >
                <h3 className="text-lg md:text-xl font-bold mb-4 text-dark">{step.title}</h3>
                <div className="w-full max-w-[200px] aspect-[9/16] bg-white rounded-lg shadow-lg overflow-hidden">
                  <img 
                    src={step.image} 
                    alt={step.title} 
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>


      <section className="bg-white text-light-blue py-12 md:py-20">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">Ready to start tracking your treasures?</h2>
          <Link to="/signup" className="bg-light-blue text-white px-6 py-3 rounded inline-block hover:bg-blue-600 transition duration-300">
            Get Started
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Home;