import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';

function AnimatedButton({ to, children }) {
  return (
    <Link 
      to={to} 
      className="bg-light-blue text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md block text-center transition-all duration-300 transform hover:scale-105 hover:rounded-xl hover:shadow-lg hover:bg-blue-600 w-full text-sm sm:text-base"
    >
      {children}
    </Link>
  );
}

function PricingTier({ title, price, yearlyPrice, features, recommended = false, onHover, isYearly }) {
  const displayPrice = isYearly ? yearlyPrice : price;
  const secondaryPrice = price === "Free" ? "Free" : (isYearly 
    ? `$${(parseFloat(yearlyPrice.split('$')[1]) / 12).toFixed(2)}/month` 
    : yearlyPrice);

    return (
      <div 
        className={`relative bg-white p-6 sm:p-8 rounded-lg shadow-lg transition-all duration-300 ${recommended ? 'border-2 border-light-blue' : 'hover:border-light-blue hover:border-2'}`}
        onMouseEnter={() => onHover(title)}
        onMouseLeave={() => onHover(null)}
      >
        {recommended && (
          <div className="absolute top-0 left-0 right-0 -translate-y-1/2 mx-auto w-max bg-gold text-white px-3 py-1 rounded-full text-xs sm:text-sm font-bold">
            Best Value
          </div>
        )}
      <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-light-blue">{title}</h3>
      <p className="text-2xl sm:text-3xl font-bold mb-1 sm:mb-2">{displayPrice}</p>
      <p className="text-xs sm:text-sm text-gray-500 mb-4 sm:mb-6">{secondaryPrice}</p>
      <ul className="mb-4 sm:mb-6 space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start text-sm sm:text-base">
            <FaCheck className="text-light-blue mr-2 mt-1 flex-shrink-0" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <AnimatedButton to="/signup">Get Started</AnimatedButton>
    </div>
  );
}

function FAQ({ question, answer }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="border-b border-gray-200 py-6 group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h4 className="text-xl font-semibold text-light-blue transition-colors duration-300 group-hover:text-blue-600">
        {question}
      </h4>
      <div 
        className={`mt-4 text-gray-600 overflow-hidden transition-all duration-700 ease-in-out 
                    ${isHovered ? 'max-height-500 opacity-100' : 'max-height-0 opacity-0'}`}
      >
        <p className="transform transition-transform duration-700 ease-in-out origin-top
                      group-hover:translate-y-0 translate-y-4">
          {answer.split(' ').map((word, index) => (
            <React.Fragment key={index}>
              <span
                className="inline-block transition-opacity duration-700 ease-in-out"
                style={{
                  opacity: isHovered ? 1 : 0,
                  transitionDelay: `${index * 20}ms`,
                }}
              >
                {word}
              </span>
              {' '}
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
}

function Pricing() {
  const [hoveredPlan, setHoveredPlan] = useState(null);
  const [isYearly, setIsYearly] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const plans = [
    {
      title: "Basic Plan",
      price: "Free",
      yearlyPrice: "Free",
      features: [
        "Secure Storage for Up to 25 Treasured Items",
        "Document One Special Property",
        "Generate 3 Detailed Reports",
        "24/7 Data Protection"
      ]
    },
    {
      title: "Premium Annual Plan",
      price: "$3.75/month",
      yearlyPrice: "$45/year",
      features: [
        "Unlimited Secure Item Storage",
        "Document All Your Properties",
        "Generate Unlimited Detailed Reports",
        "Access to Estate Planning Features",
        "30% Savings Compared to Monthly Plan",
        "24/7 Data Protection"
      ],
      recommended: true
    },
    {
      title: "Premium Monthly Plan",
      price: "$4.90/month",
      yearlyPrice: "$58.80/year",
      features: [
        "Unlimited Secure Item Storage",
        "Document All Your Properties",
        "Generate Unlimited Detailed Reports",
        "Access to Estate Planning Features",
        "Flexible Month-to-Month Commitment",
        "24/7 Data Protection"
      ]
    }
  ];

  const faqs = [
    {
      question: "What happens to my data if I cancel my subscription?",
      answer: "Your data remains securely stored for 30 days after cancellation, giving you time to download or transfer your information. After this period, it will be permanently deleted from our servers."
    },
    {
      question: "Can I upgrade or downgrade my plan at any time?",
      answer: "Yes, you can change your plan at any time. If you upgrade, the new features will be immediately available. If you downgrade, the changes will take effect at the start of your next billing cycle."
    },
    {
      question: "Is my family's data secure with Treasure Tracker?",
      answer: "Absolutely. We use industry-leading encryption and security measures to protect your data. Your family's memories and information are kept private and secure at all times."
    },
    {
      question: "How many family members can access my account?",
      answer: "With our Premium plans, you can invite up to 5 family members to access and contribute to your family's treasure collection. Each member gets their own login credentials while sharing the same secure storage space."
    },
    {
      question: "Can I access Treasure Tracker on multiple devices?",
      answer: "Yes, you can access your Treasure Tracker account on any device with an internet connection. We have web, iOS, and Android apps available, allowing you to manage your treasures from your computer, smartphone, or tablet seamlessly."
    }
  ];

  return (
    <div className="pricing py-12 sm:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl font-bold mb-2 sm:mb-4 text-center text-light-blue">Choose Your Plan</h1>
        <h2 className="text-lg sm:text-xl mb-8 sm:mb-12 text-center text-gray-600">Preserve Your Family's Legacy for Less Than a Cup of Coffee</h2>

        <div className="flex justify-center mb-8 sm:mb-12">
          <div className="bg-gray-200 p-1 rounded-full relative">
            <button
              className={`px-4 sm:px-6 py-2 rounded-full transition-all duration-300 relative z-10 ${!isYearly ? 'text-white' : 'text-gray-700'} text-sm sm:text-base`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`px-4 sm:px-6 py-2 rounded-full transition-all duration-300 relative z-10 ${isYearly ? 'text-white' : 'text-gray-700'} text-sm sm:text-base`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
            </button>
            <div 
              className={`absolute top-1 left-1 bottom-1 rounded-full bg-light-blue transition-all duration-300 ${isYearly ? 'translate-x-full' : ''}`} 
              style={{ width: 'calc(50% - 4px)' }}
            ></div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">
          {plans.map((plan, index) => (
            <div key={index} className={!isMobile && hoveredPlan && hoveredPlan !== plan.title ? 'opacity-50 transition-opacity duration-300' : 'transition-opacity duration-300'}>
              <PricingTier 
                {...plan}
                onHover={setHoveredPlan}
                isYearly={isYearly}
              />
            </div>
          ))}
        </div>

        <div className="mt-16 sm:mt-20">
          <h3 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center text-light-blue">Frequently Asked Questions</h3>
          <div className="space-y-4 mx-auto" style={{ maxWidth: '1200px' }}>
            {faqs.map((faq, index) => (
              <FAQ key={index} question={faq.question} answer={faq.answer} />
            ))}
          </div>
          <div className="mt-8 sm:mt-12 text-center max-w-xs mx-auto">
            <AnimatedButton to="/signup">Join Today</AnimatedButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;