import React, { useState } from 'react';

const FlippableCard = ({ story }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div 
      className="flip-card cursor-pointer"
      onClick={handleClick}
      style={{
        perspective: '1000px',
        width: '100%',
        height: '300px', // Reduced height for better mobile view
      }}
    >
      <div 
        className="flip-card-inner w-full h-full transition-transform duration-700 transform-style-preserve-3d"
        style={{
          transformStyle: 'preserve-3d',
          transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)'
        }}
      >
        <div 
          className="flip-card-front absolute w-full h-full backface-hidden bg-white p-4 sm:p-6 rounded-lg shadow-lg border-2 border-light-blue"
          style={{ backfaceVisibility: 'hidden' }}
        >
          <h2 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-4 text-light-blue">{story.title}</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-2 sm:mb-4 line-clamp-4 sm:line-clamp-5">{story.content}</p>
          <p className="text-xs sm:text-sm text-gray-500 absolute bottom-2 sm:bottom-4 right-4 sm:right-6">By {story.author}</p>
        </div>
        <div 
          className="flip-card-back absolute w-full h-full backface-hidden bg-white rounded-lg shadow-lg overflow-hidden border-2 border-light-blue"
          style={{ backfaceVisibility: 'hidden', transform: 'rotateY(180deg)' }}
        >
          <img 
            src={story.imageUrl} 
            alt={story.title} 
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 sm:p-4">
            <h3 className="text-base sm:text-lg font-semibold">{story.title}</h3>
            <p className="text-xs sm:text-sm">By {story.author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlippableCard;