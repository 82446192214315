import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaUser, FaBars, FaTimes } from 'react-icons/fa';
import axios from '../axiosConfig';

function Navbar() {
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [userName, setUserName] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const links = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about' },
    { name: 'Corporate Partners', path: '/corporate-partners' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'Legacy Book', path: '/legacy-book' },
    { name: 'Contact', path: '/contact' },
  ];

  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserName();
    }
  }, [isLoggedIn]);

  const fetchUserName = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const fullName = response.data.name;
      const firstName = fullName.split(' ')[0];
      setUserName(firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase());
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link 
            to="/" 
            className="flex items-center"
            onMouseEnter={() => setIsLogoHovered(true)}
            onMouseLeave={() => setIsLogoHovered(false)}
          >
            <img 
              src="/images/treasure-tracker-logo.png" 
              alt="Treasure Tracker" 
              className={`h-10 w-auto mr-2 transition-transform duration-300 ${isLogoHovered ? 'scale-110' : ''}`} 
            />
          </Link>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden text-dark hover:text-light-blue focus:outline-none"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Desktop menu */}
          <div className="hidden md:flex space-x-4 items-center">
            {links.map((link) => (
              <div
                key={link.name}
                className="relative"
                onMouseEnter={() => setHoveredLink(link.name)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                <Link
                  to={link.path}
                  className={`transition duration-300 ${
                    location.pathname === link.path
                      ? 'text-light-blue font-semibold'
                      : 'text-dark hover:text-light-blue'
                  }`}
                >
                  {link.name}
                </Link>
                {(hoveredLink === link.name || location.pathname === link.path) && (
                  <div className="absolute bottom-0 left-0 w-full h-0.5 bg-light-blue transform scale-x-0 transition-transform duration-300 origin-left animate-expand"></div>
                )}
              </div>
            ))}
            {isLoggedIn ? (
              <Link
                to="/dashboard"
                className="bg-light-blue text-white px-4 py-2 rounded hover:bg-light-purple transition duration-300 transform hover:scale-105 flex items-center"
              >
                <FaUser className="mr-2" />
                Welcome {userName}
              </Link>
            ) : (
              <Link
                to="/account-access"
                className="bg-light-blue text-white px-4 py-2 rounded hover:bg-light-purple transition duration-300 transform hover:scale-105 flex items-center"
              >
                <FaUser className="mr-2" />
                Account Access
              </Link>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} pt-4 pb-6`}>
          {links.map((link) => (
            <Link
              key={link.name}
              to={link.path}
              className={`block py-2 ${
                location.pathname === link.path
                  ? 'text-light-blue font-semibold'
                  : 'text-dark hover:text-light-blue'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              {link.name}
            </Link>
          ))}
          {isLoggedIn ? (
            <Link
              to="/dashboard"
              className="block py-2 text-light-blue font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Welcome {userName}
            </Link>
          ) : (
            <Link
              to="/account-access"
              className="block py-2 text-light-blue font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Account Access
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;