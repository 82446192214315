import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import CorporatePartners from './pages/CorporatePartners';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
// import SignUp from './pages/SignUp';
// import Login from './components/Login';
// import Dashboard from './pages/Dashboard';
// import PaymentFlow from './pages/PaymentFlow';
// import UpgradeFlow from './components/UpgradeFlow';
// import AccountAccess from './components/AccountAccess';
import ComingSoon from './components/ComingSoon';
import LegacyBook from './components/LegacyBook';
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import DataDeletion from './pages/DataDeletion';

function PrivateRoute({ children }) {
  const isAuthenticated = !!localStorage.getItem('token');
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/corporate-partners" element={<CorporatePartners />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signup" element={<ComingSoon />} />
            <Route path="/account-access" element={<ComingSoon />} />
            <Route path="/legacy-book" element={<LegacyBook />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/data-deletion" element={<DataDeletion />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}


export default App;