import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaXTwitter } from 'react-icons/fa6';

function About() {
  return (
    <div className="about py-10 sm:py-20">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl font-bold mb-8 sm:mb-12 text-center text-light-blue">Who We Are</h1>
        <div className="flex flex-col md:flex-row items-start">
          <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
            <img src="/images/jackson-white.png" alt="Jackson White" className="rounded-lg shadow-lg w-full" />
            <div className="mt-4 flex justify-center space-x-4">
              <a href="https://www.linkedin.com/in/jackson-white-founder/" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                <FaLinkedin size={24} />
              </a>
              <a href="https://x.com/jack_white_ceo" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                <FaXTwitter size={24} />
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4 text-light-blue">Jackson White</h2>
            <h3 className="text-lg sm:text-xl mb-4">Founder and CEO</h3>
            <div className="space-y-4 text-sm sm:text-base">
              <p>
                Hello! I'm Jackson White, the founder of Treasure Tracker, and I'm thrilled to share a bit about my journey with you. Growing up, I had a million career dreams—from cardiovascular surgeon to fantasy author. But it wasn't until I faced personal loss and witnessed the devastation of the Marshall Fire in Boulder that I found my true calling: helping families preserve their cherished memories.
              </p>
              <p>
                After graduating from the University of Colorado Boulder, I realized I needed a career that would constantly challenge and inspire me. Entrepreneurship was the perfect fit. With Treasure Tracker, I set out to create a digital space where people can catalog their valuables and the stories behind them, connecting generations in a meaningful way.
              </p>
              <p>
                Outside of work, I'm a passionate martial artist—a discipline I've practiced since childhood. It teaches me resilience and balance, skills that have been invaluable in my entrepreneurial journey. Just a tip: avoid sparring before important meetings—black eyes don't exactly scream "professional."
              </p>
              <p>
                I'm excited about the future of Treasure Tracker. We're continuously innovating, with features like social media sharing and collaborative storytelling on the horizon. My goal is to create a platform that not only preserves memories but also strengthens the bonds between family and friends. Join me on this journey, and let's bring your treasures to life together!
              </p>
            </div>
            <div className="text-center mt-8">
              <Link 
                to="/signup" 
                className="bg-light-blue text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md inline-block hover:bg-blue-600 transition-all duration-300 ease-in-out transform hover:scale-110 hover:rounded-xl text-sm sm:text-base"
              >
                Join Me
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;