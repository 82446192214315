import { useEffect, useState } from 'react';

function useTermlyEmbed(policyId) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    script.onload = () => setIsLoaded(true);
    script.onerror = () => setError('Failed to load Termly script');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isLoaded && window.Termly) {
      window.Termly.load(policyId);
    }
  }, [isLoaded, policyId]);

  return { isLoaded, error };
}

export default useTermlyEmbed;