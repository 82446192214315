import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaCalendar, FaComment, FaXTwitter, FaInstagram, FaFacebookF, FaTiktok, FaLinkedinIn } from 'react-icons/fa6';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = "Name is required";
    if (!formData.email.trim()) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!formData.message.trim()) tempErrors.message = "Message is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      // Simulating an API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      console.log('Form submitted:', formData);
      setFormData({ name: '', email: '', message: '' });
      setIsSubmitting(false);
      setSubmitSuccess(true);
      setTimeout(() => setSubmitSuccess(false), 5000);
    }
  };

  return (
    <div className="contact py-12 sm:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-light-blue">Contact Us</h1>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-light-blue">Get in Touch</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium">Name</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaUser className="text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`w-full pl-10 p-2.5 border rounded-md ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                      placeholder="Your name"
                    />
                  </div>
                  {errors.name && <p className="mt-1 text-red-500 text-xs">{errors.name}</p>}
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaEnvelope className="text-gray-400" />
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`w-full pl-10 p-2.5 border rounded-md ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                      placeholder="Your email"
                    />
                  </div>
                  {errors.email && <p className="mt-1 text-red-500 text-xs">{errors.email}</p>}
                </div>
                <div>
                  <label htmlFor="message" className="block mb-2 text-sm font-medium">Message</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 pt-3 pointer-events-none">
                      <FaComment className="text-gray-400" />
                    </div>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className={`w-full pl-10 p-2.5 border rounded-md h-32 ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                      placeholder="Your message"
                    ></textarea>
                  </div>
                  {errors.message && <p className="mt-1 text-red-500 text-xs">{errors.message}</p>}
                </div>
                <button 
                  type="submit" 
                  className={`w-full bg-light-blue text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
              </form>
              {submitSuccess && (
                <div className="mt-4 p-3 bg-green-100 text-green-700 rounded-md">
                  Your message has been sent successfully!
                </div>
              )}
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
              <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-light-blue">Contact Information</h2>
              <div className="space-y-4">
                <p className="flex items-center text-sm sm:text-base">
                  <FaEnvelope className="mr-3 text-light-blue flex-shrink-0" />
                  <span className="break-all">jackson.white@mytreasuretracker.com</span>
                </p>
                <p className="flex items-center text-sm sm:text-base">
                  <FaCalendar className="mr-3 text-light-blue flex-shrink-0" />
                  <a href="https://calendly.com/jackson-white-mytreasuretracker/30-minute-call" 
                     target="_blank" 
                     rel="noopener noreferrer"
                     className="text-light-blue hover:text-blue-600 transition duration-300">
                    Schedule a Call
                  </a>
                </p>
              </div>
              <h3 className="text-lg sm:text-xl font-semibold mt-6 sm:mt-8 mb-4 text-light-blue">Connect With Us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/TreasureTrakLLC" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                  <FaXTwitter size={20} />
                </a>
                <a href="https://www.instagram.com/the_treasure_tracker/" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                  <FaInstagram size={20} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61554894507503" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                  <FaFacebookF size={20} />
                </a>
                <a href="https://www.tiktok.com/@treasuretrackerllc?lang=en" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                  <FaTiktok size={20} />
                </a>
                <a href="https://www.linkedin.com/company/treasure-tracker/" target="_blank" rel="noopener noreferrer" className="text-light-blue hover:text-gold transition-colors transform hover:scale-110 duration-300">
                  <FaLinkedinIn size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;