import React from 'react';

const MediaCarousel = () => {
  const logos = [
    { id: 1, name: 'CEO Weekly', url: 'https://ceoweekly.com/treasure-tracker-preserving-family-legacies-digitally/' },
    { id: 2, name: 'Authority Magazine', url: 'https://medium.com/authority-magazine/startup-revolution-jackson-white-of-treasure-tracker-on-how-their-emerging-startup-is-changing-1eda8ddc12c9' },
    { id: 3, name: 'Business Insider', url: 'https://markets.businessinsider.com/news/stocks/rising-entrepreneur-jackson-white-launches-treasure-tracker-a-groundbreaking-app-preserving-family-legacies-1033707864' },
    { id: 4, name: 'Yahoo Finance', url: 'https://finance.yahoo.com/news/rising-entrepreneur-jackson-white-launches-141000896.html?guccounter=1' },
    { id: 5, name: 'Benzinga', url: 'https://www.benzinga.com/pressreleases/24/08/g40464312/rising-entrepreneur-jackson-white-launches-treasure-tracker-a-groundbreaking-app-preserving-family' },
    { id: 6, name: 'Economic Insider', url: 'https://economicinsider.com/jackson-white-a-visionarys-journey-in-preserving-family-heritage/' },
    { id: 7, name: 'Valiant CEO', url: 'https://valiantceo.com/jackson-white/' },
  ];

  return (
    <div className="relative overflow-hidden py-4">
      <div className="flex animate-scroll">
        {[...logos, ...logos].map((logo, index) => (
          <div key={index} className="flex-shrink-0 w-40 h-24 mx-6">
            <a 
              href={logo.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block bg-white rounded-lg shadow-md p-3 flex items-center justify-center h-full transition-transform duration-300 hover:scale-105"
            >
              <img
                src={`/logos/${logo.name.toLowerCase().replace(' ', '-')}.png`}
                alt={`${logo.name} logo`}
                className="max-w-full max-h-full object-contain"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaCarousel;