import React from 'react';
import useTermlyEmbed from '../components/useTermlyEmbed';

function PrivacyPolicy() {
  const policyId = '81c977b1-ec74-4a3a-9c7a-08b43180da3f';
  const { isLoaded, error } = useTermlyEmbed(policyId);

  return (
    <div className="privacy-policy flex flex-col min-h-screen">
      <div className="flex-grow py-12 sm:py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-light-blue">Privacy Policy</h1>
          <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md">
            {error ? (
              <div className="text-red-500 text-base sm:text-lg">
                <p>Error loading privacy policy: {error}</p>
                <p>Please try refreshing the page or contact support if the issue persists.</p>
              </div>
            ) : (
              <>
                {!isLoaded && <p className="text-base sm:text-lg">Loading Privacy Policy...</p>}
                <div className="termly-embed-container" style={{ height: 'calc(100vh - 300px)', minHeight: '400px', overflow: 'auto' }}>
                  <div 
                    name="termly-embed" 
                    data-id={policyId}
                    data-type="iframe"
                  ></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;