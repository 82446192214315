import React, { useState } from 'react';
import FlippableCard from './FlippableCard';

const LegacyBook = () => {
  const [newStory, setNewStory] = useState({ title: '', content: '', author: '', image: null });
  const [imagePreview, setImagePreview] = useState(null);

  const placeholderStories = [
    {
      id: 1,
      title: "Grandma's Antique Locket",
      content: "This heart-shaped locket has been passed down through five generations of our family. It contains a tiny photograph of my great-great-grandfather.",
      author: "Emily Johnson",
      imageUrl: "/images/antique-locket.jpg"
    },
    {
      id: 2,
      title: "Dad's First Baseball Glove",
      content: "This well-worn glove was my father's pride and joy. He used it to teach me how to play catch in our backyard every summer evening.",
      author: "Michael Rodriguez",
      imageUrl: "/images/baseball-glove.jpg"
    },
    {
      id: 3,
      title: "Great-Grandfather's Pocket Watch",
      content: "This golden pocket watch survived two world wars. It still keeps perfect time and chimes softly on the hour.",
      author: "Sarah Thompson",
      imageUrl: "/images/pocket-watch.jpg"
    },
    {
      id: 4,
      title: "Mom's Cherry Pie Recipe",
      content: "This handwritten recipe card holds the secret to my mother's famous cherry pie. The edges are worn from years of use in our family kitchen.",
      author: "David Chen",
      imageUrl: "/images/recipe-card.jpg"
    },
    {
      id: 5,
      title: "Grandfather's World War II Medal",
      content: "This bronze star was awarded to my grandfather for his bravery during World War II. It's a reminder of his sacrifice and courage.",
      author: "Lisa Nguyen",
      imageUrl: "/images/wwii-medal.jpg"
    },
    {
      id: 6,
      title: "Great-Aunt's Handmade Quilt",
      content: "This colorful quilt was stitched by my great-aunt using fabric scraps from family members' clothes. Each patch tells a story of our family history.",
      author: "Robert Taylor",
      imageUrl: "/images/handmade-quilt.jpg"
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent("New Legacy Book Submission");
    const body = encodeURIComponent(`
Title: ${newStory.title}
Author: ${newStory.author}
Content: ${newStory.content}

Note: Image attachment is not included in this email. Please set up a proper backend to handle image uploads.
    `);
    window.location.href = `mailto:jackson.white@mytreasuretracker.com?subject=${subject}&body=${body}`;
    
    // Reset form
    setNewStory({ title: '', content: '', author: '', image: null });
    setImagePreview(null);
    alert("Your story has been prepared to send via email. Please send the email to submit your story.");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewStory({ ...newStory, image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 py-8 sm:py-12">
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8 text-center text-light-blue">Legacy Book</h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-16">
          {placeholderStories.map((story) => (
            <FlippableCard key={story.id} story={story} />
          ))}
        </div>

        <div className="max-w-2xl mx-auto bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-light-blue">Share Your Story</h2>
          <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Story Title</label>
              <input
                id="title"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-light-blue focus:border-transparent"
                placeholder="Enter your story title"
                value={newStory.title}
                onChange={(e) => setNewStory({ ...newStory, title: e.target.value })}
                required
              />
            </div>
            <div>
              <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">Your Story</label>
              <textarea
                id="content"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-light-blue focus:border-transparent h-24 sm:h-32"
                placeholder="Share your treasured memory..."
                value={newStory.content}
                onChange={(e) => setNewStory({ ...newStory, content: e.target.value })}
                required
              />
            </div>
            <div>
              <label htmlFor="author" className="block text-sm font-medium text-gray-700 mb-1">Your Name</label>
              <input
                id="author"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-light-blue focus:border-transparent"
                placeholder="Enter your name"
                value={newStory.author}
                onChange={(e) => setNewStory({ ...newStory, author: e.target.value })}
                required
              />
            </div>
            <div>
              <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">Upload Image</label>
              <input
                id="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-light-blue focus:border-transparent"
                required
              />
              {imagePreview && (
                <img src={imagePreview} alt="Preview" className="mt-4 max-w-full sm:max-w-xs rounded-md shadow" />
              )}
            </div>
            <button 
              type="submit" 
              className="w-full bg-light-blue text-white p-3 rounded-md hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue"
            >
              Submit Your Story
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LegacyBook;